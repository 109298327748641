@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary-300: #9ED4B6; /* Replace with your actual theme color for primary[300] */
  --primary-500: #5EB885; /* Replace with your actual theme color for primary[500] */
}


* {
  box-sizing: border-box;
}

.dino-footer {
  padding: 96px 120px;

  &-content {

  }
}

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 120px;
  background: #F3F5F7;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 120px;
  background: #F3F5F7;
}

.base-container {
  background: #F3F5F7;
}

@media screen and (max-width: 700px) {
  .dino-footer {
    padding: 15px;
  }
  .login-container {
    padding: 15px;
  }
}


.login-card {
  background: #fff;
  padding: 48px;
  border-radius: 16px;
}

.menu-button {
  border-radius: 16px !important;
  padding-bottom: 16px !important;
  padding-top: 16px !important;
  padding-left: 16px !important;

  &__icon {
    color: #fff !important;
  }

  &__text {
    color: #fff !important;
    text-transform: capitalize !important;
  }
}

.menu-item {
  border-radius: 16px;
}

.main {
  padding: 64px;
  padding-top: 90px;
  overflow-x: hidden;
}

.loading-logo {
  width: 20%;
  //position: absolute;
  //top: 40%;
  //left: 50%;
}

@media screen and (max-width: 700px) {
  .main {
    padding: 10px;
    padding-top: 20px;
  }
  .loading-logo {
    width: 80% !important;
  }
}

.reservations-main {
  padding: 64px;
  padding-top: 120px;
  overflow-x: hidden;
}

@media screen and (max-width: 900px) {
  .reservations-main {
    padding: 10px;
    padding-top: 20px;
  }
}

.web-main {
  padding: 64px;
}

.dashboard-card {
  padding: 32px;
  border-radius: 16px;
  background: white;
}

.white-card {
  padding: 32px;
  border-radius: 16px;
  background: white;
  margin-bottom: 20px;
}
.white-card-calendar {
  padding: 5px;
  border-radius: 16px;
  background: white;
  margin-bottom: 20px;
}

.white-card-sp {
  padding: 32px;
  border-radius: 16px;
  background: white;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px)  {
  .white-card-sp {
    padding: 2px;
  }
  .table-heading {
    font-size: 70% !important;
  }

}

.reservation-name {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}

.reservation-accepted {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #5EB885;
}

.reservation-pending {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #ED8802;
}

.active-radio {
  background: #EFF8F3;
  border: 1px solid #9ED4B6;
  border-radius: 16px;
}

.active-color {
  cursor: pointer;
  width: 96px;
  height: 96px;
  border-radius: 16px;
  outline: #4CAF50 solid 3px
}

.active-color {
  cursor: pointer;
  width: 96px;
  height: 96px;
  border-radius: 16px;
  outline: #4CAF50 solid 3px
}

.not-active-color {
  cursor: pointer;
  width: 96px;
  height: 96px;
  border-radius: 16px;
  outline: #F3F5F7 solid 3px
}

.reservation-detail-table {
  border-collapse:separate;
  border-spacing: 0 1em;
}

.bank-card {
  padding: 20px 10px;
  border-radius: 10px;
  border: 1px solid #2a3746;
  background: #606e8c;
}

.active-dot {
  background: #4CAF50;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

// reservation

.accepted {
  align-self: center;
  background: var(--primary-300) !important; /* Replace with your theme variable */
  color: #fff;
}

.waiting {
  align-self: center;
  background: #FEF4E6;
  color: #ED8802;
}

.ignore {
  align-self: center;
  background: #e7e7e7;
  color: #7c7c7c;
}

.icon-style {
  color: var(--primary-500); /* Replace with your theme variable */
  padding: 8px;
  background: #fff;
  border: 2px solid;
  border-color: var(--primary-500); /* Replace with your theme variable */
  border-radius: 50%;
  width: 42px !important;
  height: 42px !important;
}

.inverted-icon-style {
  background: var(--primary-500); /* Replace with your theme variable */
  padding: 8px;
  color: #fff;
  border: 2px solid;
  border-color: var(--primary-500); /* Replace with your theme variable */
  border-radius: 50%;
  width: 42px !important;
  height: 42px !important;
}
