.calendar {
    max-width: 100%;
    margin: auto;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.calendar-month-grid,
.calendar-week-grid,
.calendar-year {
    display: grid;
    gap: 5px;
}

.calendar-month-grid {
    grid-template-columns: repeat(7, 1fr);
}

.calendar-week-grid {
    grid-template-columns: repeat(7, 1fr);
}

.calendar-year {
    grid-template-columns: repeat(4, 1fr);
}

.calendar-day,
.calendar-month {
    padding: 2px;
    background: #f4f4f4;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    height: 150px;
}

.calendar-day-view {
    padding: 20px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.calendar-month-header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
}

.calendar-event {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 2px;
}

@media (max-width: 600px) {
    .calendar-year {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 600px) {
    .calendar-month-grid,
    .calendar-week-grid {
        grid-template-columns: repeat(7, 1fr);
    }
}

.calendar-day-view {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.day-view-grid {
    display: grid;
    /*gap: 15px;*/
    position: relative;
}

.calendar-hour {
    position: relative;
    display: flex;
    align-items: self-start;
    height: 100px;
    padding-left: 60px;
}

.hour-label {
    position: absolute;
    left: 0;
    font-weight: bold;
    font-size: 0.9em;
}

.hour-line {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    position: absolute;
    top: 0;
    left: 0;
}

.event-box {
    position: absolute;
    left: 10%;
    padding: 1px;
    border-radius: 3px;
    font-size: 0.85em;
    line-height: 1.2;
    overflow: hidden;
    cursor: pointer;
}

.calendar-event-count {
    padding: 5px;
    border-radius: 3px;
    background: #5EB885;
    color: #fff;
    font-weight: bold;
}

.current-time-line {
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: red;
    z-index: 1; /* Ensures the line appears on top of other elements */
}

.today-circle {
    /*display: inline-block;*/
    width: 24px;
    height: 24px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
